import {mapMutations, mapActions, mapGetters} from "vuex";

//sections


export default {
    name: "site-map",
    components: {},
    data() {
        return {
            crumbs: [
                {src: 'home', title: this.$t('home.title'), slug: '/'},
                {src: 'site-map', title: this.$t('Карта сайту'), slug: ''},
            ],

        }
    },
    mounted() {
        this.getSiteMap().then(() => {
            document.title = `${'Карта сайту'} | karbosnab`
        })
        this.setRequestFlag(true);
    },
    created() {

    },
    computed: {
        ...mapGetters({
            globalRequestsFlag: 'system/globalRequestsFlag',
            siteMap: 'setting/siteMap',
        })
    },

    methods: {
        ...mapActions({
            getSiteMap: 'setting/GET_SITEMAP'
        }),
        ...mapMutations({
            setRequestFlag: 'system/SET_REQUESTS_FLAG',
        }),
        /**
         * @param {{ slug: string, type: string, title: string }} link
         * @return void
         */
        onLinkClick(link) {
            console.log(link);

            window.location.href = this.getUrl(link);
        },
        /**
         * @param {{ slug: string, type: string, title: string }} link
         * @return string
         */
        getUrl(link) {
            const lang = 'uk';

            if (link.type === 'category') {
                return `/${lang}/filter/${link.slug}` ;
            }

            // if (link.type === 'page') {
            // }
            return `/${lang}/${link.slug}` ;
        }
    }
}
